<template>
  <v-card class="mb-8" v-if="requestData">
    <v-card-title>{{'ec.data.payment_type'| t}}</v-card-title>

    <v-card-text>
      <v-radio-group row v-model="requestData.payment_type">
        <div
          v-for="paymentType in paymentTypes"
          :key="paymentType.value"
          class="mr-2 mb-2"
        >
          <v-radio
            :label="paymentType.name"
            :value="paymentType.value"
          ></v-radio>

          <div
            v-if="paymentType.description"
            v-html="nl2br(paymentType.description)"
            class="font-weight-light grey--text body-2 ml-8 mb-4"
          ></div>
        </div>
      </v-radio-group>
    </v-card-text>
  </v-card>
</template>

<script lang="babel" type="text/babel">
export default {
  props: {
    paymentTypes: Array,
    paymentConfig: {
      type: Object,
      default: () => null,
    },
    value: {
      type: Object,
    },
  },
  data: () => ({
    requestData: null,
  }),
  mounted() {
    this.loadPaymentTypes()
  },
  methods: {
    async loadPaymentTypes() {
      try {
        const types = await this.$api.collection.ecApi.paymentTypes()
        this.$emit('setPaymentTypes', types)
      } catch (error) {
        console.warn(error)
      }
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler() {
        this.requestData = window.eagleLodash.cloneDeep(this.value)
      },
    },
    requestData: {
      deep: true,
      handler() {
        if(window.eagleLodash.isEqual(this.requestData, this.value)) return
        this.$emit('input', window.eagleLodash.cloneDeep(this.requestData))
      },
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
